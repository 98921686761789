<script>
import AppTag from "@/components/ui/AppTag.vue";
import AppDropdownOption from "@/components/ui/AppDropdownOption.vue";
import AppLoader from "@/components/ui/AppLoader.vue";

export default {
  name: "AppDropdown",

  components: {
    AppTag,
    AppDropdownOption,
    AppLoader
  },

  props: {
    multiple: { type: Boolean, default: false },
    value: { type: [Array, String, Number, Object], default: () => (this.multiple ? [] : "") },
    label: { type: String, default: "" },
    description: {type: String, default: "", required: false},
    options: {type: [Array, Object], required: true},
    placeholder: {type: String, default: "Select..."},
    limitDisplay: {type: Number, default: 3},
    leftIcon: {type: String, default: "", required: false},
    onCheckIcon: {type: Boolean, default: false},
    prependIcon: {type: String, default: ''},
    offCheckIcon: {type: Boolean, default: false},
    returnObject: {type: Boolean, default: false},
    itemText: {type: [String, Function], default: ""},
    itemDescription: {type: String, default: ""},
    itemValue: {type: String, default: ""},
    autocomplete: {type: Boolean, default: false},
    loading: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    editable: {type: Boolean, default: false},
    rules: {type: Array, default: () => []},
  },

  data() {
    return {
      showDropdown: false,
      searchQuery: "",

      inputValue: '',
      error: false,
      errorMessage: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.inputValue = this.getItemText(val);
      },
    },
  },

  computed: {
    selectedItems: {
      get() {
        return this.multiple ? this.value : [this.value];
      },
      set(val) {
        this.$emit("input", this.multiple ? val : val[0]);
      },
    },

    displayedItems() {
      return this.selectedItems.slice(0, this.limitDisplay);
    },

    selectedOptions() {
      return this.filteredOptions.filter(item => this.isSelected(item));
    },

    unselectedOptions() {
      return this.filteredOptions.filter(item => !this.isSelected(item));
    },

    hiddenItemsCount() {
      return this.selectedItems.length - this.limitDisplay;
    },

    selectedItemsText() {
      return this.selectedItems.map(item => {
        if (typeof item === 'object') {
          return this.getItemText(item);
        }
        const foundItem = this.options.find(option => this.getItemValue(option) === item);
        return foundItem ? this.getItemText(foundItem) : !this.itemValue[0] ? item : null;
      });
    },

    filteredOptions() {
      if (this.searchQuery) {
        if (this.itemText) {
          return this.options.filter((option) =>
            this.getItemText(option).toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        } else {
          return this.options.filter((option) =>
            option.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }
      }
      return this.options;
    },
  },


  mounted() {
    if (this.value.length > 0) {
      if (typeof this.value === 'object') {
        this.selectItem(this.value)
        this.selectValue(this.value)
      } else {
        this.inputValue = this.value
      }
    }
  },

  methods: {
    toggleDropdown() {
      this.multiple ? this.showDropdown = true : this.showDropdown = !this.showDropdown;
    },

    selectItem(item) {
      const itemValue = this.getItemValue(item);
      if (this.multiple) {
        const index = this.selectedItems.findIndex((selected) =>
          this.getItemValue(selected) === itemValue
        );
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        } else {
          this.selectedItems.push(this.returnObject ? item : itemValue);
        }
      } else {
        //this.selectedItems[0] = this.returnObject ? item : itemValue;
        this.selectedItems[0] = item;
        this.showDropdown = false;
      }
      this.searchQuery = ""

      this.calculateItem()
    },

    calculateItem() {
      let emittedValue;
      if (this.multiple) {
        emittedValue = this.selectedItems;
      } else if (this.returnObject) {
        emittedValue = this.selectedItems[0];
      } else {
        emittedValue = this.itemValue.length > 0 ? this.selectedItems[0][this.itemValue] : this.selectedItems[0]
      }

      this.$emit("input", emittedValue);
    },

    selectValue(item) {
      if (this.editable) {
        this.inputValue = this.getItemText(item)
      }
      this.onBlur()
    },

    removeItem(index) {
      this.selectedItems.splice(index, 1);
      this.$emit("input", this.multiple ? this.selectedItems : this.selectedItems[0]);
    },

    clickOutsideMenu() {
      this.showDropdown = false;
    },

    getItemValue(item) {
      if (item !== null) {
        if (typeof (item) === 'object') {
          return this.itemValue ? item[this.itemValue] : this.getItemText(item);
        } else {
          return item;
        }
      }
    },

    isSelected(item) {
      const itemValue = this.getItemValue(item);
      return this.selectedItems.some(selected => this.getItemValue(selected) === itemValue);
    },

    getItemText(item) {
      if (item !== null) {
        return item[this.itemText] || this.getDisplayTag(item);
      }
    },

    getDisplayTag(item) {
      if (typeof this.itemText === 'function') {
        return this.itemText(item)
      } else if (typeof item === 'object') {
        return item[this.itemText]
      } else {
        return item
      }
    },

    isEmpty(selectedItems) {
      if (Array.isArray(selectedItems)) {
        return selectedItems.length === 0;
      } else if (typeof selectedItems === 'string') {
        return selectedItems.trim() === '';
      } else if (selectedItems && typeof selectedItems === 'object') {
        return Object.keys(selectedItems).length === 0;
      } else {
        return !selectedItems;
      }
    },

    resetSelected() {
      this.selectedItems = []
    },

    onBlur() {
      this.validate();
    },

    validate() {
      let value = this.editable ? this.inputValue : this.selectedItems
      let isValid = true;
      this.errorMessage = null
      this.error = false
      for (const rule of this.rules) {
        const result = rule(value);
        if (result !== true) {
          this.error = true
          isValid = false;
          this.errorMessage = result;
          break;
        }
      }
      return isValid;
    },
  },
};
</script>

<template>
  <div class="dropdown" v-click-outside="clickOutsideMenu">
    <label v-if="label">{{ label }}
      <span v-if="required">*</span>
    </label>
    <div @focusout="onBlur" class="dropdown-container" :class="{ 'loading' : loading }" @click="toggleDropdown"
         ref="myElement">
      <img v-if="leftIcon" alt="left-icon" class="left-icon" :src="leftIcon">
      <div :class="multiple ? 'selected-items' : 'selected-text'">
        <template v-if="multiple">
          <template v-if="selectedItems[0]">
            <AppTag
              v-for="(item, index) in displayedItems"
              :key="index"
              :label="itemValue[0] ? selectedItemsText[index] : getDisplayTag(item)"
              close
              color="var(--blue-20)"
              class="selected-items-tag"
              text_color="var(--blue-70)"
              @close="removeItem(index)"
            />
          </template>
          <template v-else><span class="selected-items__placeholder">{{ placeholder }}</span></template>
        </template>

        <template v-else-if="!editable">
            <span class="selected-items__placeholder" v-if="isEmpty(selectedItems[0])">
            {{ placeholder }}
          </span>
          <span class="selected-items__text" v-else>
            {{ itemValue[0] ? selectedItemsText[0] : getDisplayTag(selectedItems[0]) }}
          </span>
        </template>
        <template v-else-if="editable">
          <input type="text" class="dropdown-input" v-model="inputValue" @input="$emit('changeInput', inputValue)" @blur="$emit('blur', inputValue)">
        </template>

        <div class="hidden-count" v-if="multiple && hiddenItemsCount > 0">
          <AppTag
            :label="'+' + hiddenItemsCount"
            text_color="var(--blue-70)"
            color="var(--grey-10)"
          />
        </div>
      </div>
      <AppLoader v-if="loading" class="loader" :size="24"/>
      <img alt="chevron" class="dropdown-icon" :class="{ rotated: showDropdown }"
           src="@/assets/img/icons/chevron-black.svg">
    </div>

    <small class="dropdown-description">
      <span v-if="description && !showDropdown">{{ description }}</span>
    </small>
    <small class="error-message" v-if="errorMessage && error">{{ errorMessage }}</small>

    <div class="dropdown-menu" v-if="showDropdown && options.length > 0">
      <div class="dropdown-search-wrapper" v-if="autocomplete">
        <img src="@/assets/img/icons/search-black.svg" alt="search-icon" class="search-icon">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Поиск"
          class="dropdown-search"
        />
      </div>

      <AppDropdownOption
        v-for="(item, index) in selectedOptions"
        :key="index "
        :on-check-icon="onCheckIcon"
        :off-check-icon="offCheckIcon"
        :item="itemText"
        :description="item[itemDescription]"
        :prepend-icon="prependIcon"
        :display-tag="getDisplayTag(item)"
        :is-selected="isSelected(item)"
        @select="selectItem(item), selectValue(item)"
      />

      <div class="dropdown-menu__clear" v-if="multiple && selectedItems[0]" @click="resetSelected">
        Удалить выбранные
      </div>

      <AppDropdownOption
        v-for="(item, index) in unselectedOptions"
        :key="index + getDisplayTag(item)"
        :on-check-icon="onCheckIcon"
        :off-check-icon="offCheckIcon"
        :item="itemText"
        :description="item[itemDescription]"
        :prepend-icon="prependIcon"
        :display-tag="getDisplayTag(item)"
        :is-selected="isSelected(item)"
        @select="selectItem(item), selectValue(item)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.dropdown {
  display: flex;
  flex-direction: column;
  font-family: $font-family;

  label {
    color: var(--grey-70);
    margin-bottom: 4px;
    @include font-description-1;

    > span {
      color: var(--red-60);
    }
  }

  input {
    border: none;
    outline: none;
    flex-grow: 1;
    padding: 0;
    width: 100%;
    @include font-subtitle-medium;
  }

  small {
    color: var(--grey-60);
    //margin-top: 4px;
    margin-top: -6px;
    height: 16px;
    @include font-description-1;

    &.error-message {
      color: var(--red-100)
    }
  }

  &-container {
    border: 1px solid var(--grey-40);
    border-radius: 12px;
    background-color: #FFFFFF;
    display: flex;
    padding: 6px 8px 6px 12px;
    height: 40px;
    cursor: pointer;
    transition: border 0.2s;
    position: relative;

    &:hover {
      border: 1px solid var(--grey-70);
    }

    &:hover {
      border: 1px solid var(--grey-70);
    }

    &:focus-within {
      border: 1px solid var(--blue-70);
      box-shadow: 0 0 0 2px rgba(178, 207, 241, 0.50);
    }

    &.loading {
      opacity: 0.5;
    }
  }
}

.selected-items {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  gap: 4px;
  max-width: calc(100% - 8px);
  position: relative;

  &__placeholder {
    color: var(--grey-50);
    align-self: center;
    @include font-description-0;
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 2px);
    position: relative;
  }
}

.selected-text {
  display: grid;
  width: 100%;
  margin-right: 4px;
}

.selected-items-tag {
  white-space: nowrap;

  &:not(:first-child) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 2px;
  }
}

.loader {
  opacity: 1 !important;
  z-index: 10;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hidden-count {
  margin-right: 4px;
  @include font-description-0;
}

.dropdown-icon {
  margin-left: auto;
  color: var(--grey-70);
  transition: transform 0.3s ease;
}

.left-icon {
  height: 16px;
  margin: 6px 8px 6px 0;
}

.dropdown-menu {
  margin-top: 60px;
  max-height: 255px;
  overflow-y: auto;
  position: absolute;
  z-index: 10;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid var(--grey-50);
  background: #FFF;
  backdrop-filter: blur(15px);

  &__clear {
    display: flex;
    width: 100%;
    padding: 4px 8px 8px 12px;
    justify-content: flex-end;
    align-items: center;
    color: var(--grey-70);
    text-align: right;
    line-height: 20px;
    cursor: pointer;
    transition-duration: 0.2s;
    @include font-description-semibold-0;

    &:active {
      color: var(--grey-60);
    }
  }
}

.dropdown-search-wrapper {
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 4px;
  border-bottom: 1px solid var(--grey-50);

  &:focus-within {
    border-radius: 8px;
    border: 1px solid var(--Brand-Blue70, #305CA8);
    box-shadow: 0 0 0 2px rgba(178, 207, 241, 0.50);
  }

  .search-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .dropdown-search {
    width: 100%;
    padding: 6px 16px 4px 0;
    height: 32px;
    margin-bottom: 4px;
    border: none;
    outline: none;

    &::placeholder {
      color: var(--grey-50);
      line-height: 20px;
      @include font-description-semibold-0;
    }
  }
}

.rotated {
  transform: rotate(180deg);
}

</style>