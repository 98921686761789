<script>
import AppButton from "@/components/ui/AppButton.vue";
import RequestFormSteps from "./RequestFormSteps.vue";
import RequestFormRateCost from "./RequestFormRateCost.vue";
import RequestFormMainInfo from "@/components/request-form-upd/RequestFormMainInfo.vue";
import RequestFormRecipient from "@/components/request-form-upd/RequestFormRecipient.vue";
import RequestFormInvoices from "@/components/request-form-upd/invoice-tab/RequestFormInvoices.vue";
import RequestFormExtras from "@/components/request-form-upd/RequestFormExtras.vue";
import RequestFormProduct from "./RequestFormProduct.vue";
import RequestFormNotification from "@/components/request-form-upd/RequestFormNotification.vue";
import AlarmGoodsForm from "@/components/request-form/AlarmGoodsForm.vue";

export default {
  name: "RequestForm",

  components: {
    AppButton,
    RequestFormSteps,
    RequestFormRateCost,
    RequestFormMainInfo,
    RequestFormRecipient,
    RequestFormInvoices,
    RequestFormExtras,
    RequestFormProduct,
    RequestFormNotification,
    AlarmGoodsForm
  },

  props:{
    args:{
      type: Object,
      required: true
    },
    
    autoDeliveryPlace: {
      type: [String, Object],
      required: false
    },
    
    order: {
      type: [Array, Object],
      required: false,
      default: () => null,
    },
    
    date: {
      type: [Date, String],
      required: false
    },
  },

  data() {
    return {
      step: 1,
      selected_service: {},

      routerInfo: null,
      deliveryPriceInfo: [],
      showServiceLoader: false,

      isButtonDisabled: true,
      showNotification: false,
      notificationType: 'success',
      notificationMessage: 'Данные агента и инструкции по коносаменту направит менеджер в течении 3-х рабочих часов',
      notificationTitle: 'Ваш заказ успешно оформлен!',

      showAlarmGoodsForm: false,
      showInnForm: false,

      storeFormData: {},

      formdata: {
        order_key: Math.floor(Math.random() * 1e8),
        client_id: null,
        rate: null,
        agent_id: null,
        agent_name: null,
        
        addservices:[],
        schedule: [],
        
        fraight_currency_id: null,
        is_vtt: false,
        ready_date: null,
        files_name: [],
        invoices: [],
      },
    };
  },

  watch: {
    step() {
      this.validateCurrentStep();
    }
  },

  computed: {
    storageKey() {
      const STORAGE_KEY = 'request_data';
      const key = `${STORAGE_KEY}_${this.args.rate.base_place_from}_${this.args.rate.base_place_to}`
      return key;
    },

    isStorageData() {
      return this.$_getsetting(this.storageKey) !== null
    }
  },

  mounted(){
    this.findDate()
    this.getUser()
    this.fillData()
    this.validateCurrentStep()
  },

  methods: {
    nextStep() {
      this.step < 5 ? this.step++ : this.saveOrder()
    },

    prevStep() {
      this.step <= 1 ? this.step : this.step--
    },

    findDate() {
      if (this.isStorageData) {
        this.notificationType = 'continue'
        this.notificationMessage = 'У вас есть незавершенный заказ, продолжите его или отмените, если он уже не актуальный'
        this.notificationTitle= 'Продолжить оформление заказа?'
        this.showNotification = true
      }
    },

    getUser() {
      this.$postapi(this.$address + this.$getters.getUserData.uri, {
        method: this.$getters.getUserData.name,
        key: this.$_getsetting('token'),
      }).then(data => {
        if (data.error == 0){
          const profileData = JSON.parse(data.message)
          if (this.$_getsetting('client_id') != -1) {
            // this.hasValidData(profileData.payers_data)
            if (profileData.orders_count.all_cnt < 1 && profileData.client_data[0].is_refusal === 0 && this.showAlarmGoodsForm === false) {
              this.showAlarmGoodsForm = true
            }
          }
        }
      });
    },

    hasValidData(arr) {
      return arr.some(obj => {
        const hasInn = obj.inn !== undefined && obj.inn !== null && obj.inn !== '';

        const hasValidAccountsData =
          obj.accounts_data &&
          obj.accounts_data[0] &&
          obj.accounts_data[0].bik !== undefined &&
          obj.accounts_data[0].bik !== null &&
          obj.accounts_data[0].bik !== '' &&
          obj.accounts_data[0].accounts_data !== undefined &&
          obj.accounts_data[0].accounts_data !== null &&
          obj.accounts_data[0].accounts_data !== '';
        this.showInnForm = !(hasInn && hasValidAccountsData);
      });
    },

    fillStoreData() {
      this.showNotification = false
      this.formdata = {}
      this.storeFormData = {}
      const parseStoreData = JSON.parse(this.$_getsetting(this.storageKey))
      this.formdata = JSON.parse(JSON.stringify(parseStoreData))
      this.storeFormData = parseStoreData
    },

    fillData() {
      this.formdata.rate = JSON.parse(JSON.stringify(this.args.rate))
     this.formdata.client_id = this.$_getsetting("client_id")
     this.formdata.ready_date = this.date
    },

    removeData() {
      this.formdata.rate = JSON.parse(JSON.stringify(this.args.rate))
      if (this.formdata.gds_volume.length === 0) {
        this.formdata.gds_volume = 0
      }
      this.formdata.rate.title = ""
      this.formdata.rate.unit_code = ""
      delete this.formdata.cont_type
    },

    updateField(step) {
      let updatedData = {}
      switch (step) {
        case 1:
          updatedData = this.$refs.mainInfoForm.OnEditChange();
          break;
        case 2:
          updatedData = this.$refs.consigneeInfoForm.OnEditChange();
          break;
        case 3:
          this.formdata.invoices.push()
          break;
        case 4:
          updatedData = this.$refs.goodsForm.OnEditChange();
          break;
        case 5:
          updatedData = this.$refs.extrasForm.OnEditChange();
          break;
      }
      if (Object.keys(updatedData).length > 0) {
        this.formdata = { ...this.formdata, ...updatedData }
      }
    },

    validateCurrentStep() {
      switch (this.step) {
        case 1:
          this.isButtonDisabled = !this.$refs.mainInfoForm.validate();
          break;
        case 2:
          this.isButtonDisabled = !this.$refs.consigneeInfoForm.validate();
          break;
        case 3:
          this.formdata.invoices.length > 0 ? this.isButtonDisabled = false : this.isButtonDisabled = true;
          break;
        case 4:
          this.isButtonDisabled = !this.$refs.goodsForm.validate();
          break;
        case 5:
          this.isButtonDisabled = !this.$refs.extrasForm.validate();
          break;
      }
    },

    addService(selected_service, value) {
      if (selected_service.cost != null) {
        if (value === true) {
          let arr = {
            id: selected_service.service_id,
            cost: selected_service.cost,
            service: selected_service.service,
            cur_code: selected_service.cur_code
          }
          if (!this.formdata.addservices.some(service => service.id === selected_service.service_id)) {
            this.formdata.addservices.push(arr)
          } else {
            let index = this.formdata.addservices.findIndex(el => el.id === selected_service.service_id);
            this.formdata.addservices[index] = arr
          }
          
        } else {
          const index = this.formdata.addservices.findIndex(n => n.id === selected_service.service_id);
          if (index !== -1) {
            this.formdata.addservices.splice(index, 1);
          }
        }
      }
    },

    getRoute(delivery_to) {
      if (delivery_to.length > 0) {
        this.$postapi(this.$address + this.$getters.getRoute.uri, {
          method: this.$getters.getRoute.name,
          place_from: this.args.rate.base_place_to_name,
          place_to: delivery_to,
        }).then(data => {
          if (data.error == 0) {
            this.routerInfo = JSON.parse(data.message)
            if (this.routerInfo.kilometers) {
              setTimeout(this.getAutoServiceInfo, 500);
            } else {
              this.routerInfo.kilometers = 0
              setTimeout(this.getAutoServiceInfo, 500);
            }
          }
        });
      }
    },

    getAutoServiceInfo() {
      this.showServiceLoader = true
      this.clearDeliveryPrice()
      this.$postapi(this.$address + this.$getters.getAutoServiceInfo.uri, {
        method: this.$getters.getAutoServiceInfo.name,
        distance: this.routerInfo.kilometers,
        unit_type: this.args.rate.unit_type,
        weight: this.formdata.gds_gross_weight ? Number(this.formdata.gds_gross_weight) : 0,
        is_vtt: Number(this.formdata.is_vtt),
        is_moscow: this.args.rate.base_place_to_name.includes('Москва') === true ? 1 : 0,
      }).then(data => {
        if (data.error == 0) {
          this.deliveryPriceInfo = JSON.parse(data.message)
          for (let i = 0; i < this.deliveryPriceInfo.length; i++) {
            this.addService(this.deliveryPriceInfo[i], true)
          }
          this.showServiceLoader = false
        }
      });
    },

    checkService(element) {
      this.updateField(5)
      if (this.formdata.is_car === false) {
        this.clearDeliveryPrice()
      } else if (this.routerInfo != null) {
        this.getAutoServiceInfo()
      }
      this.showServiceLoader = true
      this.$postapi(this.$address + this.$getters.getServiceInfo.uri, {
        method: this.$getters.getServiceInfo.name,
        service_id: element.name,
        client_id: this.$_getsetting('client_id'),
      }).then(data => {
        if (data.error == 0){
          this.selected_service = JSON.parse(data.message)
          this.addService(this.selected_service, element.value)
        }
      });
      this.showServiceLoader = false
    },

    clearDeliveryPrice() {
      for (let i = 0; i < this.deliveryPriceInfo.length; i++) {
        this.addService(this.deliveryPriceInfo[i], false)
      }
    },

    addInvoice(data) {
      this.formdata.invoices = data
      this.validateCurrentStep()
    },

    exitForm() {
      this.notificationType = 'exit'
      this.notificationTitle = 'Уверены, что хотите выйти?'
      this.notificationMessage = 'Вы можете сохранить введенные данные и вернуться к оформлению позже'
      this.showNotification = true
    },

    errorForm() {
      this.notificationType = 'error'
      this.notificationTitle = 'Внимание! Ошибка при передаче данных'
      this.showNotification = true
    },

    OnSave(value, n) {
      const config = {
        timeout: 4000 // Таймаут запроса 4 секунды
      };

      const handleRetry = () => {
        if (n > 0) {
          this.notificationMessage = `Повторное сохранение ${Math.abs(n - 6)} из 5`;
          this.errorForm();
          setTimeout(() => {
            this.OnSave(value, n - 1);
          }, 1500);
        } else {
          this.notificationMessage = '';
          setTimeout(() => {
            this.showNotification = false;
          }, 2000);
        }
      };

      this.$postapi(this.$address + this.$setters.setPrefRequestData.uri, {
        method: this.$setters.setPrefRequestData.name,
        rate: value,
        client_id: this.$_getsetting('user_id'),
        token: this.$_getsetting("token")
      }, config)
        .then(data => {
          if (data && data.message != null && data.message !== 0) {
            this.showSuccessNotify();
            setTimeout(() => {
              this.closeForm();
            }, 2500);
          } else {
            handleRetry();
          }
        })
        .catch( () => {
            handleRetry();
            this.$refs.notificationForm.onLoading()
        });
    },

    saveOrder() {
      this.$refs.consigneeInfoForm.fileUpdate();
      this.$refs.goodsForm.fileUpdate();
      this.fillData()
      this.removeData()

      this.$nextTick(() => {
        if (this.formdata.gds_volume.length === 0) {
          this.formdata.gds_volume = 0
        }
        const formdataCopy = { ...this.formdata }
        this.OnSave({ value: formdataCopy }, 5)
      });
    },

    updateFile(newFiles) {
      newFiles.forEach((file) => {
        if (!this.formdata.files_name.includes(file)) {
          this.formdata.files_name.push(file);
        }
      });
    },

    saveToLocalStorage() {
      this.updateField(this.step)
      const request_data = JSON.stringify(this.formdata);
      this.$_setsetting(this.storageKey, request_data);
      this.showNotification = false
      this.$emit('close')
    },

    showSuccessNotify() {
      this.notificationType = 'success'
      this.notificationMessage = 'Данные агента и инструкции по коносаменту направит менеджер в течении 3-х рабочих часов'
      this.notificationTitle = 'Ваш заказ успешно оформлен!'
      this.showNotification = true
    },

    closeForm() {
      this.$_removesetting(this.storageKey)
      this.$emit('close')
    },
  },
};
</script>

<template>
  <div class="request-form">
    <span class="request-form__close" v-ripple @click="exitForm()">
      <img src="../../assets/img/icons/cancel.svg" />
    </span>
    <div class="request-form__blocks">
      <div class="request-form__main">
        <RequestFormSteps :step="step">
          <template v-slot:step1>
            <RequestFormMainInfo
              ref="mainInfoForm"
              v-show="step === 1"
              :rate="args"
              :order_key="formdata.order_key"
              @addService="addService($event, true)"
              @validateForm="validateCurrentStep"
            />
          </template>
          <template v-slot:step2>
            <RequestFormRecipient
              ref="consigneeInfoForm"
              v-show="step === 2"
              :rate="args"
              :order_key="formdata.order_key"
              :storeData="isStorageData ? storeFormData : null"
              @updateFile="updateFile"
              @validateForm="validateCurrentStep"
            />
          </template>
          <template v-slot:step3>
            <RequestFormInvoices
              :rate="args"
              v-show="step === 3"
              :storeData="isStorageData ? storeFormData : null"
              @addInvoice="addInvoice"
            />
          </template>
          <template v-slot:step4>
            <RequestFormProduct
              ref="goodsForm"
              v-show="step === 4"
              :rate="args"
              :order_key="formdata.order_key"
              @updateFile="updateFile"
              :storeData="isStorageData ? storeFormData : null"
              @validateForm="validateCurrentStep"
            />
          </template>
          <template v-slot:step5>
            <RequestFormExtras
              v-show="step === 5"
              ref="extrasForm"
              :rate="args"
              :storeData="isStorageData ? storeFormData : null"
              @getRoute="getRoute"
              @checkService="checkService"
              @validateForm="validateCurrentStep"
            />
          </template>
        </RequestFormSteps>
        <p class="request-form__notice">
          <span class="request-form__notice--red">*</span> обязательные поля для заполнения
        </p>
      </div>
      <div class="request-form__services">
        <RequestFormRateCost 
          :rate="args"
          :add-services="formdata.addservices"
          :show-loader="showServiceLoader"
        />
      </div>
      <div class="request-form__btns">
        <AppButton
          @click="prevStep"
          :disabled="step === 1"
          color="ghost"
          label="Назад"
          :left-icon="require('../../assets/img/icons/arrow-back-grey.svg')"
          size="medium"
        />

        <AppButton
          :color="step !== 5 ? 'blue' : 'orange'"
          @click="nextStep(), updateField(step - 1)"
          :label="step === 5 ? 'Отправить' : 'Дальше'"
          :right-icon="step === 5 ? null : require('../../assets/img/icons/arrow-back.svg')"
          :disabled="isButtonDisabled"
          size="medium"
        />
      </div>
    </div>

    <v-dialog v-model="showAlarmGoodsForm" width="60%" persistent>
      <v-card v-if="showAlarmGoodsForm">
        <AlarmGoodsForm @close="showAlarmGoodsForm = false"/>
      </v-card>
    </v-dialog>

    <RequestFormNotification
      :show="showNotification"
      v-if="showNotification"
      :notificationType="notificationType"
      ref="notificationForm"
      @continue="fillStoreData"
      @exit="closeForm"
      @save="saveToLocalStorage"
      @closeNotification="showNotification = false"
    >
      <p slot="title"> {{notificationTitle}}</p>
      <p slot="subtitle"> {{notificationMessage}}</p>
    </RequestFormNotification>

    <v-dialog
      v-model="showInnForm"
      width="40%"
    >
      <v-card class="pa-6">
        <v-card-title>
          Внимание!
        </v-card-title>
        <v-card-text class="card-main-text">
          Для оформления заявки вам необходимо
          <router-link to="/AddCompanyData?inn=1">
            <b>заполнить реквизиты компании</b>
          </router-link>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang='scss'>
.request-form {
  z-index: 100;
  border-radius: 16px;
  background: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px 34px 19px 26px;

  @media (max-width: $tablet-width){
    padding: 32px 0 16px;
  }

  @media (max-width: $mobile-width) {
    padding: 0 0 16px;
  }

  &__close {
    position: absolute;
    top: 23px;
    right: 17px;
    border-radius: 50%;
    cursor: pointer;
    padding: 4px;

    @media (max-width: $mobile-width) {
      top: 8px;
      right: 8px;
    }
  }

  &__blocks {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 416px);
    gap: 16px 22px;

    @media (max-width: $tablet-width) {
      grid-template-columns: 1fr;
    }
  }

  &__services {
    padding: 19px 16px 12px;
    border-radius: 16px;
    background-color: var(--grey-5);
    margin-top: 13px;
    height: auto;
    position: relative;
    //min-height: 600px;

    @media (max-width: $tablet-width){
      min-height: unset;
      padding: 16px 32px;
      border-radius: unset
    }

    @media (max-width: $mobile-width) {
      padding: 8px 16px 16px;
    }
  }

  &__main {
    grid-row: 1 / 3;
    height: 100%;
    min-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 24px 0 0 32px;

    @media (max-width: $tablet-width){
      padding: 32px 32px 0;
    }

    @media (max-width: $mobile-width){
      padding: 24px 16px 0;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 12px;

    @media (max-width: $tablet-width) {
      justify-content: center;
    }
  }

  &__notice {
    color: #838e9c;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: auto;

    &--red {
      color: var(--red-60);
    }
  }

  &__btns {
    display: flex;
    gap: 8px;
    //margin: auto 32px 16px auto;
  }
}
</style>