<script>
export default {
  name: 'InvoiceCard',

  props: {
    invoice: { type: Object, required: true },
    ikey: String,
  },

  data() {
    return {
      pencilIcon: require('../../../assets/img/icons/pencil.svg'),
      trashIcon: require('../../../assets/img/icons/trash.svg'),

      activeForm: false
    }
  },

  methods: {
    editInvoice() {
      this.activeForm = !this.activeForm
      this.$emit('editInvoice', { activeForm: this.activeForm, ikey: this.ikey} )
    }
  }
}
</script>

<template>
  <div class="inv-card" :class="{ 'active' : activeForm }">
    <div class="inv-card__header">
      <span>Инвойс:</span>
      <span>{{ invoice.invoice_number }}</span>
      <div class="inv-card__header-button">
        <img class="inv-card__header-icon" :src="pencilIcon" alt="pencil" @click="editInvoice">
        <img class="inv-card__header-icon" :src="trashIcon" alt="delete" @click="$emit('deleteInvoice', invoice.invoice_number)">
      </div>
    </div>
    <div class="inv-card__body">
      <div class="inv-card__body-item">
        <span>ФИО:</span>
        <span>{{ invoice.shipper_fio }}</span>
      </div>
      <div class="inv-card__body-item">
        <span>Отправитель:</span>
        <span>{{ invoice.shipper_name }}</span>
      </div>
      <div class="inv-card__body-item">
        <span>E-mail:</span>
        <span><a :href="'mailto:' + invoice.email">{{ invoice.shipper_email }}</a></span>
      </div>
      <div class="inv-card__body-item">
        <span>Телефон:</span>
        <span>{{ invoice.shipper_phone }}</span>
      </div>
      <div class="inv-card__body-item">
        <span>Адрес:</span>
        <span>{{ invoice.shipper_address }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.inv-card {
  border-radius: 12px;
  border: 0.5px solid var(--grey-30);
  padding: 26px 24px;

  &.active {
    border: 0.5px solid var(--blue-50);
    box-shadow: 0 0 0 2px rgba(178, 207, 241, 0.50);
  }

  &__header {
    border-bottom: 1px solid #ddd;
    border-radius: 5px 5px 0 0;
    padding-bottom: 20px;
    display: flex;

    > span {
      &:first-child {
        width: 100px;
        display: inline-block;
        color: var(--grey-60);
        margin-right: 8px;
        @include font-body-3;
      }

      &:last-child {
        color: var(--blue-70);
        @include font-body-bold-3;
      }
    }

    &-button {
      margin-left: auto;
      display: flex;
    }

    &-icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin-left: 16px;
    }
  }

  &__body {
    &-item {
      margin: 16px 0;

      > span {
        &:first-child {
          width: 100px;
          display: inline-block;
          color: var(--grey-60);
          margin-right: 8px;
          @include font-body-3;
        }

        &:last-child {
          color: var(--grey-70);
          @include font-body-3;
        }
      }
    }
  }
}
</style>
