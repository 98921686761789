<script>
export default {
  name: "RequestFormSteps",
  props: {
    step: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {};
  },
  computed: {
    currentStep() {
      return this.step;
    },
  },
};
</script>

<template>
  <div class="steps-form">
    <v-stepper alt-labels v-model="currentStep">
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > 1" step="1">
          Основное
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 2" step="2">
          Получатель
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 3" step="3">
          Отправители
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 4" step="4">
          Товар
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 5" step="5">
          Доп. услуги
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items class="full-w">
        <v-stepper-content step="1">
          <slot name="step1"></slot>
        </v-stepper-content>
        <v-stepper-content step="2">
          <slot name="step2"></slot>
        </v-stepper-content>
        <v-stepper-content step="3">
          <slot name="step3"></slot>
        </v-stepper-content>
        <v-stepper-content step="4">
          <slot name="step4"></slot>
        </v-stepper-content>
        <v-stepper-content step="5">
          <slot name="step5"></slot>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<style scoped lang="scss">
.steps-form{
  margin-bottom: auto;

  @media (max-width: $mobile-width){
      padding: 0 15px;
  }
}
</style>