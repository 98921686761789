<script>
import AppLoader from "@/components/ui/AppLoader.vue";

export default {
  name: "AppTextarea",

  props: {
    label: String,
    description: String,
    error: String,
    error_message: String,
    placeholder: String,
    type: { type: String, default: "text" },
    value: { required: true },
    loading: Boolean,
    disabled: Boolean,
    success: Boolean,
    required: Boolean,
    rows: { default: 5 }
  },

  components: {
    AppLoader
  },

  data() {
    return {
      inputValue: ""
    }
  },

  mounted() {
    this.inputValue = this.value;
  },

  watch: {
    value(newValue) {
      this.inputValue = newValue;
    },
    inputValue(newValue) {
      this.$emit('input', newValue);
    }
  }
}
</script>

<template>
  <div
    :class="['textarea-container', { 'has-label': label, 'has-description': description, 'loading': loading }]"
  >
    <label v-if="label">
      {{ label }}
      <span v-if="required">*</span>
    </label>

    <div :class="['input-wrapper', { 'input-wrapper-error': error, 'input-wrapper-disabled': disabled, 'input-wrapper-success': success }]">
      <textarea
        :disabled="disabled || loading"
        :placeholder="placeholder"
        :rows="rows"
        v-model="inputValue"
      />

      <AppLoader v-if="loading" class="loader" :size="24"/>
    </div>

    <small>
      <span v-if="description && !error_message">
        {{ description }}
      </span>
    </small>
    <small class="error-message" v-if="error_message && error">{{ error_message }}</small>
  </div>
</template>

<style scoped lang="scss">
.textarea-container {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  position: relative;

  label {
    color: var(--grey-70);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;

    > span {
      color: var(--red-60);
    }
  }

  small {
    color: var(--grey-60);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 4px;
    height: 16px;
  }

  .error-message {
    color: var(--red-100)
  }

  &.loading {
    opacity: 0.5;

    .loader {
      opacity: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--grey-40);
  border-radius: 12px;
  background-color: #FFFFFF;
  transition-duration: 0.2s;

  @media (max-width: $mobile-width) {
    height: 56px;
  }

  &:hover {
    border: 1px solid var(--grey-70);
  }

  &:focus-within {
    border: 1px solid var(--blue-70);
    box-shadow: 0 0 0 2px rgba(178, 207, 241, 0.50);
  }

  &-error {
    border: 1px solid var(--red-100);
    box-shadow: 0 0 0 2px rgba(255, 137, 146, 0.50);

    &:hover,
    &:focus-within {
      border: 1px solid var(--red-100);
      box-shadow: 0 0 0 2px rgba(255, 137, 146, 0.50);
    }
  }

  &-success {
    border: 1px solid var(--green-100);
    box-shadow: 0 0 0 2px rgba(179, 218, 161, 0.50);

    &:hover,
    &:focus-within {
      border: 1px solid var(--green-100);
      box-shadow: 0 0 0 2px rgba(179, 218, 161, 0.50);
    }
  }

  &-disabled {
    border: 1px solid var(--grey-50);
    background-color: var(--grey-10);
    cursor: not-allowed;
    color: var(--grey-70);

    &:hover {
      border: 1px solid var(--grey-50);
    }

    > textarea {
      cursor: not-allowed;
      color: var(--grey-70) !important;
    }
  }

  > textarea {
    border: none;
    outline: none;
    flex-grow: 1;
    padding: 0 8px;
    font-size: 14px;
    font-weight: 500;
    color: var(--blue-100);

    &::placeholder {
      color: var(--grey-50);
    }
  }
}


.has-label .input-container {
  height: 80px;
}

.has-description .input-container {
  height: 80px;
}
</style>
